@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8f8;
}

.app {
    background-color: #f8f8f8;
}

#root {
    background-color: #f8f8f8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-modal {
    top: 20px;
}

.icon-info {
    color: #17a2b8;
}

.icon-success {
    color: #1890ff;
}

.icon-danger {
    color: #ff4d4f;
}

.ant-table-wrapper {
    margin-top: 16px;
}

.ant-table-title {
    padding-left: 0;
}

.ant-page-header {
    border-radius: 20px;
}

.ant-form-item-control-input {
    border-radius: 20px;
}

table {
    border: 1px solid #f0f0f0;
    border-bottom: 0 !important;
}

.ant-table {
    overflow-y: auto;
}

button.ant-btn {
    border-radius: 8px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
}

.ant-pagination-item-link {
    align-items: center;
    justify-content: center;
    display: flex !important;
}

button.ant-btn {
    border-radius: 8px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
}

.ant-pagination-item-link {
    align-items: center;
    justify-content: center;
    display: flex !important;
}

.ant-picker-calendar-date-content {
    ul.events {
        padding-left: 0;
    }
}
