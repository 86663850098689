.form-modal {
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .frm {
        width: 100%;
        background: #ecb08c;
        border-radius: 10px;

        .form-detail {
            padding-left: 28px;
            padding-right: 28px;

            .btn-submit {
                border-radius: 20px;
                width: 100%;
                min-height: 32px;
                background-image: linear-gradient(-90deg, #de40bb, #feb75b);
            }
            .custom-input {
                min-height: 36px;
                border-radius: 5px;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .app {
        overflow-x: hidden;
    }
}

@media (max-width: 768px) {
    .pro-sidebar.collapsed {
        left: -80px;
        width: 100% !important;
        min-width: 100% !important;
    }
}

@media (max-width: 768px) {
    .pro-sidebar {
        position: fixed;
        left: -270px;
    }
}

.app .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 25%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
}

.table-description {
    table {
        border: none;
    }
    .ant-descriptions-row {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
    }
}

@media (max-width: 768px) {
    .app .btn-toggle {
        display: flex;
    }
}

.border-radius-default {
    border-radius: 8px !important;
}

.custom-card {
    height: 100%;
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
