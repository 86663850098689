@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap);
body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8f8;
}

.app {
    background-color: #f8f8f8;
}

#root {
    background-color: #f8f8f8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-modal {
    top: 20px;
}

.icon-info {
    color: #17a2b8;
}

.icon-success {
    color: #1890ff;
}

.icon-danger {
    color: #ff4d4f;
}

.ant-table-wrapper {
    margin-top: 16px;
}

.ant-table-title {
    padding-left: 0;
}

.ant-page-header {
    border-radius: 20px;
}

.ant-form-item-control-input {
    border-radius: 20px;
}

table {
    border: 1px solid #f0f0f0;
    border-bottom: 0 !important;
}

.ant-table {
    overflow-y: auto;
}

button.ant-btn {
    border-radius: 8px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
}

.ant-pagination-item-link {
    align-items: center;
    justify-content: center;
    display: flex !important;
}

button.ant-btn {
    border-radius: 8px;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px;
}

.ant-pagination-item-link {
    align-items: center;
    justify-content: center;
    display: flex !important;
}

.ant-picker-calendar-date-content {
    ul.events {
        padding-left: 0;
    }
}

.form-modal{height:100vh;display:flex;align-items:center;flex-direction:column;justify-content:center}.form-modal .frm{width:100%;background:#ecb08c;border-radius:10px}.form-modal .frm .form-detail{padding-left:28px;padding-right:28px}.form-modal .frm .form-detail .btn-submit{border-radius:20px;width:100%;min-height:32px;background-image:linear-gradient(-90deg, #de40bb, #feb75b)}.form-modal .frm .form-detail .custom-input{min-height:36px;border-radius:5px}@media(max-width: 767.98px){.app{overflow-x:hidden}}@media(max-width: 768px){.pro-sidebar.collapsed{left:-80px;width:100% !important;min-width:100% !important}}@media(max-width: 768px){.pro-sidebar{position:fixed;left:-270px}}.app .btn-toggle{cursor:pointer;width:35px;height:35px;background:#353535;color:#fff;text-align:center;border-radius:25%;align-items:center;justify-content:center;font-size:15px;display:none}.table-description table{border:none}.table-description .ant-descriptions-row{display:flex;flex-wrap:wrap;grid-gap:8px;gap:8px}@media(max-width: 768px){.app .btn-toggle{display:flex}}.border-radius-default{border-radius:8px !important}.custom-card{height:100%}.custom-card .card-body{display:flex;flex-direction:column;justify-content:space-between}
